



/* @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;1,500&family=Source+Sans+Pro&display=swap'); */
  

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@600;700;800;900&family=Rubik:wght@400;500;800&display=swap');

body {
  background: #ffffff;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Catamaran', sans-serif;
  font-weight: bold;
  line-height: inherit;
}

h1 {
  color: #252525;
  font-size: 3em;
  line-height: normal;
}

h2 {
  color: #353535;
  font-size: 2em;
  padding-bottom: 0.625rem;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 0;
}

h3,
h3 a {
  color: #454545;
}

p {
  color: #757575;
  font-size: 1rem;
  font-weight: normal;
  text-align: justify;
  line-height: 1.5rem;
}

html {
  -webkit-font-smoothing: antialiased;
}

a {
  color: #252525;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none !important;
}

a,
input,
button,
.form-control {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #29ca8e;
  outline: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #000000;
}



.section-btn {
  background: transparent;
  border-radius: 50px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: inherit;
  font-weight: normal;
  padding: 15px 30px;
  transition: 0.5s;
}

.section-btn:hover {
  background: #ffffff;
  border-color: transparent;
}




.section-title {
  padding-bottom: 40px;
}

.about-section-title {
  text-align: center;
  padding-bottom: 0.9375rem;
}

.section-title h2 {
  margin: 0;
}

.section-title small {
  display: block;
}

.overlay {
  background: rgba(20, 20, 20, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.entry-form {
  background: #252020;
  border-radius: 100%;
  text-align: center;
  padding: 6em;
  width: 450px;
  height: 450px;
}

.entry-form h2 {
  color: #ffffff;
  margin: 0;
}

.entry-form .form-control {
  background: transparent;
  border: 0;
  border-bottom: 0.0625rem solid;
  border-radius: 0;
  box-shadow: none;
  height: 2.8125rem;
  margin: 0.625rem 0;
}

.entry-form .submit-btn {
  background: #ffffff;
  border-radius: 3.125rem;
  border: 0;
  color: #252020;
  width: 50%;
  height: 3.125rem;
  margin: 1.875rem auto;
  margin-bottom: 0.625rem;
}

.entry-form .submit-btn:hover {
  background: #3f51b5;
  color: #ffffff;
}

section {
  position: relative;
  padding: 20px 0;
}


#team,
#testimonial {
  background: #f9f9f9;
}

#team,
#testimonial {
  text-align: center;
}

#google-map iframe {
  border: 0;
  width: 100%;
  height: 390px;
}

:root {
  --nav-font-color: #21bbcd;
  --navlink-hover-color: #880e4f;
}

.logo {
  width: 11.25rem;
  margin-left: 1.5625rem;
}


.social-container {
	position: relative;
	bottom: 1rem;
}

.fa-stack {
	width: 2em;
	margin-bottom: 0.75rem;
	margin-right: 0.375rem;
	font-size: 1.25rem;
}

.social-container .fa-stack:last-child {
	margin-right: 0;
}

.fa-stack .fa-stack-1x {
    color: #ffffff;
	transition: all 0.2s ease;
}

.fa-stack .fa-stack-2x {
	color: #21bbcd;
	opacity: 0.8;
	transition: all 0.2s ease;
}

.fa-stack:hover .fa-stack-2x {
	opacity: 1;
}



.navbar {
  background: #ffffff;
  border-top: 0.3125rem solid #29ca8e;
  border-bottom: 0;
  -webkit-box-shadow: 0 0.0625rem 1.875rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0.0625rem 1.875rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.0625rem 1.875rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  padding: 0;
  padding: 0.75rem 0;
}

.nav {
  text-transform: uppercase;
}

.nav-link {
  margin-right: 0.9375rem;
  margin-left: 0.9375rem;
  color: var(--nav-font-color) !important;
}

.nav-link:active,
.nav-link:focus {
  background-color: #21bbcd;
  color: #ffffff !important;
}

.nav-link:hover {
  background-color: #21bbcd;
  color: #ffffff !important;
}






#home h1 {
  color: #ffffff;
}

#home {
  padding: 0;
}
#home h3 {
  color: #f9f9f9;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 5px 0 40px 0;
}

@media (min-width: 768px) {
  .carousel-fade .col-md-6 {
    padding-left: 0;
  }
}

.home-slider .caption {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  background-color: rgba(20,20,20,0.2);
  height: 100%;
  color: #fff;
  cursor: e-resize;
}

.home-slider .item {
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  height: 650px;
}~

.caption h3 a { color: #FFF; }
.caption h3 a:hover { color: #FF3; }

.home-slider .item-first {
  background-image: url(./images/slider-image1.jpg);
  background-position: center;
}

.home-slider .item-second {
  background-image: url(./images/slider-image2.jpg);
}

.home-slider .item-third {
  background-image: url(./images/slider-image3.jpg);
}



.feature-thumb {
  border: 1px solid #f0f0f0;
  padding: 5em 3em;
}

.feature-thumb span {
  background: #21bbcd;
  border-radius: 50px;
  color: #ffffff;
  font-size: 1.5625rem;
  font-weight: bold;
  display: inline-block;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
  margin-bottom: 0.3125rem;
}

.feature-thumb h3 {
  margin: 0.625rem 0;
}

.about-info,
.team-thumb,
.footer-info {
  margin-bottom: 3.125rem;
}



.figure {
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
}

.figure span {
  background: #21bbcd;
  border-radius: 50px;
  color: #ffffff;
  font-size: 1.5625rem;
  font-weight: bold;
  display: inline-block;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
  margin-bottom: 0.3125rem;
}

.icon-thumb{
  background: #3f51b5;
  border-radius: 50px;
  color: #ffffff;
  font-size: 1.5625rem;
  font-weight: bold;
  display: inline-block;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
  margin-bottom: 0.3125rem;
}

.team-thumb {
  background: #ffffff;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.team-info {
  padding: 20px 30px;
}

.team-image img {
  width: 100%;
}

.social-icon {
  border-top: 1px solid #f0f0f0;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  background: #ffffff;
  color: #252020;
}

.team-info h3 {
  margin: 0;
}





#courses .section-title {
  text-align: center;
}

#courses .owl-theme .owl-nav {
  margin-top: 30px;
}

#courses .owl-theme .owl-nav [class*=owl-] {
  border-radius: 2px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
}

.courses-thumb {
  background: #f9f9f9;
  position: relative;
}

.courses-top {
  position: relative;
}

.courses-image {
  background-color: #fff;
}

.fcourses-image{
  background-color: #fff;
  width: 100%;
  height: 100px;
}

.courses-date {
  background: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.6));
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px 25px;
}

.courses-date span,
.courses-author span {
  font-size: 14px;
  font-weight: bold;
}

.courses-date span {
  color: #ffffff;
  display: inline-block;
  margin-right: 10px;
}

.courses-detail {
  padding: 25px 25px 15px 25px;
}

.courses-detail h3 {
  margin: 0 0 2px 0;
}

.courses-detail ul{
  margin: 10px;
}


.courses-detail ul li{
  font-weight: bolder;
}

.courses-info {
  border-top: 1px solid #f0f0f0;
  position: relative;
  clear: both;
  padding: 15px 25px;
}

.courses-author,
.courses-author span {
  display: inline-block;
  vertical-align: middle;
}

.courses-author img {
  display: inline-block !important;
  border-radius: 50px;
  width: 50px !important;
  height: 50px;
  margin-right: 10px;
}

.courses-price {
  float: right;
}

.courses-price span {
  background: #29ca8e;
  border-radius: 2px;
  color: #ffffff;
  display: block;
  padding: 5px 10px;
}

.courses-price.free span {
  background: #3f51b5;
}


#follstack .section-title {
  text-align: center;
}
.fullstackrow{
  display: flex;
  flex-direction: column;
}





#testimonial .item {
  background: #ffffff;
  margin: 20px 0;
  padding: 4em 3em;
  text-align: left;
}

#courses .col-md-4,
#testimonial .col-md-4 {
  display: block;
  width: 100%;
}

#testimonial .item > p {
  font-size: 16px;
  line-height: 26px;
}

.tst-rating {
  margin-bottom: 15px;
}

.tst-rating .fa {
  color: #3f51b5;
}

.tst-image,
.tst-author {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1.25rem;
  text-align: left;
}

.tst-image img {
  border-radius: 50px;
  width: 60px !important;
  height: 60px;
  margin-right: 15px;
}

.tst-author h3 {
  margin: 0;
}

.tst-author span {
  color: #808080;
  font-size: 15px;
}




#contact {
  background: #21bbcd;
}

#contact h2 {
  color: #ffffff;
}

#contact .section-title {
  padding-bottom: 20px;
}

#contact h2 > small,
#contact p,
#contact a {
  color: #f9f9f9;
}

#contact-form {
  padding: 1em 0;
}

#contact-form .col-md-12,
#contact-form .col-md-4 {
  padding-left: 0;
}

#contact-form .form-control {
  border: 0;
  border-radius: 0.3125rem;
  box-shadow: none;
  margin: 10px 0;
}


.contact-image {
  margin-top: 0;
}

#contact-form input {
  height: 50px;
}

#contact-form input[type='submit'] {
  border-radius: 50px;
  border: 1px solid transparent;
}

#contact-form input[type='submit']:hover {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}








footer {
  background: #252020;
  padding: 120px 0;
}

footer .section-title {
  padding-bottom: 10px;
}

footer h2 {
  font-size: 20px;
}

footer a,
footer p {
  color: #909090;
}

footer strong {
  color: #d9d9d9;
}

footer address p {
  margin-bottom: 0;
}

footer .social-icon {
  margin-top: 25px;
}

.footer-info {
  margin-right: 2em;
}

.footer-info h2 {
  padding: 0;
}

.footer-info p{
  font-weight: bolder;
}

.footer_menu h2 {
  margin-top: 2em;
}

.footer_menu ul {
  margin: 0;
  padding: 0;
}

.footer_menu li {
  display: inline-block;
  list-style: none;
  margin: 5px 10px 5px 0;
}

.newsletter-form .form-control {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #303030;
  height: 50px;
  margin: 5px 0;
}

.newsletter-form input[type="submit"] {
  background: transparent;
  border: 1px solid #f9f9f9;
  border-radius: 50px;
  color: #ffffff;
  display: block;
  margin-top: 20px;
  outline: none;
  width: 50%;
}

.newsletter-form input[type="submit"]:hover {
  background: #29ca8e;
  border-color: transparent;
}

.newsletter-form .form-group span {
  color: #909090;
  display: block;
  font-size: 12px;
  margin-top: 20px;
}




.social-icon {
  position: relative;
  padding: 0;
  margin: 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
  margin-bottom: 5px;
}

.social-icon li a {
  border-radius: 100px;
  color: #29ca8e;
  font-size: 15px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-decoration: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  position: relative;
  margin: 5px 5px 5px 0;
}

.social-icon li a:hover {
  background: #29ca8e;
  color: #ffffff;
}